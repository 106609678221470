import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { account, addAccountCompanySetting, addAccountEmployeeSetting, addAccountPersonSetting, changeAccountPassword, resetAccountPassword, updateAccountSetting, updateCompanySetting } from 'src/app/models/account.model';
import { apiResponse } from 'src/app/models/apiResponse.model';
import { assessment } from 'src/app/models/assessment.model';
import { BidFilterUpdate } from 'src/app/models/bidFilter.model';
import { announcement } from 'src/app/models/communication.model';
import { updateInspection } from 'src/app/models/inspection';
import { InspectionFilterUpdate } from 'src/app/models/inspectionFilter.model';
import { addImage, assignExpertToValuation, assignValuationTasks, available, buyValuation, closeValuationTasks, contactTask, dateDelivery, expertBid, finishValuation, inspectionAddress, linkExpert, moveValuation, paymentRequest, planInspection, prepareSale, Reactivate, registerTransport, saleStatus, sellAction, topDownCalculation, transportAddress, updateCustomer, updateDamage, updateImage, updateValuationDocument, valuationClaim, valuationComment, valuationDocument } from 'src/app/models/request.model';
import { Statistics } from 'src/app/models/statistics.model';
import { environment } from '../../environments/environment';
import { addSubscription, bidFilterConfig, company, companyAlias, companyLogin, companyProperty, dealership, editSubscription, employee, employeeProperty, inspectionFilterConfig, patchRequestPreference, personalLogin, requestPreference, specialism, updatePerson } from '../models/crm.model';
import { ModelMapping, createModelMapping } from 'src/app/models/modelMapping';
import { valuationOffer } from '../models/valuationOffer.model';
import { accountingJob } from '../models/accounting.model';

@Injectable()
export class ApiService {
    private api_base: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    /*-------------------------
    == GENERAL- CALLS ==
    -------------------------*/
    //GET
    dateOperation(operation: string, days: number, date?: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('operation', String(operation));
        Params = Params.append('days', String(days));
        if (date) Params = Params.append('date', date);

        return this.http.get<apiResponse>(this.api_base + '/api/v1/date',
            { headers: RequestHeaders, params: Params });
    }

    getApiJobs(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);

        return this.http.get<apiResponse>(this.api_base + '/api/v1/job/',
            { headers: RequestHeaders, params: Params });
    }

    /*-------------------------
    == ACCOUNT CALLS ==
    -------------------------*/
    //GET
    getAccountSettings(limit: any, page: any, orderBy: string | null, filter: any, fields: any, defaultFilter: any, companyId?: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
            .append('limit', String(limit))
            .append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = Params.append('filter', filter);
        if (defaultFilter) Params = Params.append('defaultFilter', defaultFilter);
        if (fields) Params = Params.append('fields', fields);
        if (companyId) Params = Params.append('companyId', String(companyId));
        return this.http.get<apiResponse>(`${this.api_base}/account/v1/setting/`,
            { headers: RequestHeaders, params: Params });
    }

    getSettingTypes(limit: number, page: number, orderBy?: string | null, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/account/v1/setting-type',
            { headers: RequestHeaders, params: Params });
    }

    resetAccountPassword(data: resetAccountPassword) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/account/reset', data, { headers: RequestHeaders });
    }

    changeAccountPassword(data: changeAccountPassword) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/account', data, { headers: RequestHeaders });
    }

    //PUT
    updateAccountModuleSetting(id: string, params: updateAccountSetting) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/module-setting/' + id, params, { headers: RequestHeaders });
    }

    updateAccountPersonSetting(id: string, params: updateAccountSetting) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/person-setting/' + id, params, { headers: RequestHeaders });
    }

    updateAccountEmployeeSetting(id: string, params: updateAccountSetting) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/employee-setting/' + id, params, { headers: RequestHeaders });
    }

    updateAccountCompanySetting(id: string, params: updateCompanySetting) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/company-setting/' + id, params, { headers: RequestHeaders });
    }

    updateAccountHoldingSetting(id: string, params: updateAccountSetting) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/holding-setting/' + id, params, { headers: RequestHeaders });
    }

    updateAccount(id: string, params: account) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/person-account/' + id, params, { headers: RequestHeaders });
    }

    updateCompanyAccount(id: string, params: companyLogin) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/account/v1/company-account/' + id, params, { headers: RequestHeaders });
    }

    // POST
    addAccountPersonSetting(params: addAccountPersonSetting) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/account/v1/person-setting', params, { headers: headers });
    }

    addAccountEmployeeSetting(params: addAccountEmployeeSetting) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/account/v1/employee-setting', params, { headers: headers });
    }

    addAccountCompanySetting(params: addAccountCompanySetting) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/account/v1/company-setting', params, { headers: headers });
    }

    addPersonalLogin(data: personalLogin) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/account/v1/person-account', data, { headers: RequestHeaders });
    }

    addCompanyAccount(data: companyLogin) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/account/v1/company-account', data, { headers: RequestHeaders });
    }

    //DELETE
    deleteAccountModuleSetting(id: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/account/v1/module-setting/' + id, { headers: RequestHeaders });
    }

    deleteAccountPersonSetting(id: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/account/v1/person-setting/' + id, { headers: RequestHeaders });
    }

    deleteAccountEmployeeSetting(id: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/account/v1/employee-setting/' + id, { headers: RequestHeaders });
    }

    deleteAccountCompanySetting(id: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/account/v1/company-setting/' + id, { headers: RequestHeaders });
    }

    deleteAccountHoldingSetting(id: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/account/v1/holding-setting/' + id, { headers: RequestHeaders });
    }

    deleteCompanyAccount(id: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/account/v1/company-account/' + id, { headers: RequestHeaders });
    }

    /*-------------------------
    == INSPECTION- CALLS ==
    -------------------------*/
    // GET
    getInspections(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);

        return this.http.get<apiResponse>(this.api_base + '/inspection/v1/',
            { headers: RequestHeaders, params: Params });
    }

    getInspection(id: number, fields: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/inspection/v1/' + id, { headers: RequestHeaders, params: Params });
    }

    getReports(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);

        return this.http.get<apiResponse>(this.api_base + '/inspection/v1/report',
            { headers: RequestHeaders, params: Params });
    }

    getReport(id: number, fields: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/inspection/v1/report/' + id, { headers: RequestHeaders, params: Params });
    }

    // PUT
    updateInspection(id: number, data: updateInspection) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.put<apiResponse>(this.api_base + '/inspection/v1/' + id, data, { headers: headers });
    }

    assessInspection(id: number, data: assessment) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.put<apiResponse>(this.api_base + '/inspection/v1/' + id + '/assessment', data, { headers: headers });
    }

    approveInspection(data: any, id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection-approve', data, { headers: RequestHeaders });
    }

    rejectInspection(data: any, id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection-reject', data, { headers: RequestHeaders });
    }

    inspection(id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection-request', { headers: RequestHeaders });
    }

    cancelInspection(id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection-request', { headers: RequestHeaders });
    }

    planInspection(id: number, data: planInspection) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection', data, { headers: RequestHeaders });
    }

    inspectionAddress(id: number, data: inspectionAddress) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection-address', data, { headers: RequestHeaders });
    }

    /*-------------------------
    == REQUEST CALLS ==
    -------------------------*/

    // GET
    getValuations(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);

        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/',
            { headers: RequestHeaders, params: Params });
    }

    getTasks(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/task',
            { headers: RequestHeaders, params: Params });
    }

    getTaskTypes(limit: number, page: number, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/task-type',
            { headers: RequestHeaders, params: Params });
    }

    getValuationStatusTypes(limit: number, page: number, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/status-type',
            { headers: RequestHeaders, params: Params });
    }

    getLogs(valuationId: number, limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + valuationId + '/log',
            { headers: RequestHeaders, params: Params });
    }

    getValuationID(taxId: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + taxId, { headers: RequestHeaders, params: Params });
    }

    getAutotrackLinkValuation(id: number) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/autotrack');
    }

    getGaspedaalLinkValuation(id: number) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/gaspedaal');
    }

    getAutowereldLinkValuation(id: number) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/autowereld');
    }

    getAutotrackLinkVehicleList(id: number) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + id + '/autotrack');
    }

    getGaspedaalLinkVehicleList(id: number) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + id + '/gaspedaal');
    }

    getAutowereldLinkVehicleList(id: number) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + id + '/autowereld');
    }

    getValuationDocument(documentId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(this.api_base + '/request/v1/valuation/document/' + documentId + '/content', { headers: RequestHeaders, responseType: 'blob' });
    }

    getValuationCommentTypes(limit: any, page: any, orderBy: string, filter: any, fields: any, defaultFilter: any) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/comment-type?' + 'limit=' + limit + ' & page=' +
            page + '&orderBy=' + orderBy + '&filter=' + filter + '&defaultFilter=' +
            defaultFilter + '&fields=' + fields);
    }

    getValuationResultTypes(limit: any, page: any, orderBy: string, filter: any, fields: any, defaultFilter: any) {
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/result-type?' + 'limit=' + limit + ' & page=' +
            page + '&orderBy=' + orderBy + '&filter=' + filter + '&defaultFilter=' +
            defaultFilter + '&fields=' + fields);
    }

    getReferenceBids(limit: number, page: number, fields?: string | null, defaultFilter?: string | null, filter?: string | null, groupBy?: string | null, orderBy?: string | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
            .append('limit', String(limit))
            .append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = Params.append('filter', filter);
        if (defaultFilter) Params = Params.append('defaultFilter', defaultFilter);
        if (fields) Params = Params.append('fields', fields);
        if (groupBy) Params = Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(`${this.api_base}/request/v1/valuation/reference-bid`, { headers: RequestHeaders, params: Params });
    }

    getValuationInspectionAddress(valuationId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + valuationId + '/inspection-address', { headers: RequestHeaders });
    }

    getMappingModels(limit: number, page: number, fields?: string | null, defaultFilter?: string | null, filter?: string | null, groupBy?: string | null, orderBy?: string | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
            .append('limit', String(limit))
            .append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = Params.append('filter', filter);
        if (defaultFilter) Params = Params.append('defaultFilter', defaultFilter);
        if (fields) Params = Params.append('fields', fields);
        if (groupBy) Params = Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/mapping/model/', { headers: RequestHeaders, params: Params });
    }

    getMappingModel(id: number, fields?: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams();
        if (fields) {
            Params = Params.append('fields', fields);
        }
        return this.http.get<apiResponse>(`${this.api_base}/request/v1/mapping/model/${id}`, { headers: RequestHeaders, params: Params });
    }

    getVehicleList(limit: number, page: number, fields?: string, defaultFilter?: string, filter?: string, groupBy?: string, orderBy?: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
            .append('limit', String(limit))
            .append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = Params.append('filter', filter);
        if (defaultFilter) Params = Params.append('defaultFilter', defaultFilter);
        if (fields) Params = Params.append('fields', fields);
        if (groupBy) Params = Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-list/', { headers: RequestHeaders, params: Params });
    }

    getVehicleListId(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-list/' + id, { headers: RequestHeaders, params: Params });
    }

    getVehicleListVehicleId(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + id, { headers: RequestHeaders, params: Params });
    }

    // PUT
    moveValuation(taxid: string, data: moveValuation) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + taxid + '/move', data, { headers: RequestHeaders });
    }

    assignValuationTasks(data: assignValuationTasks) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/task/assign', JSON.stringify(data), { headers: RequestHeaders });
    }

    extendBid(taxid: string, params: any) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        let RequestParams = new HttpParams();
        RequestParams = params;
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + taxid + '/extend-bid',
            RequestParams, { headers: RequestHeaders });
    }

    buyValuation(taxid: string, params: buyValuation) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + taxid + '/buy', params, { headers: RequestHeaders });
    }

    updateValuation(taxid: number, data: any) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + taxid, data, { headers: RequestHeaders });
    }

    valuateValuation(data: any, id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/valuate', data, { headers: RequestHeaders });
    }

    revaluateValuation(data: any, id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/revaluate', data, { headers: RequestHeaders });
    }

    resellValuation(data: any, id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/resell', data, { headers: RequestHeaders });
    }

    preValuateValuation(data: any, id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/pre-valuate', data, { headers: RequestHeaders });
    }

    preValuateVehicleListVehicle(data: any, id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + id + '/pre-valuate', data, { headers: RequestHeaders });
    }

    invoiceReceived(id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/invoice-received', { headers: RequestHeaders });
    }

    updateVehicleSpecification(taxid: string, data: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('content-type', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + taxid + '/vehicle-specification', data, { headers: RequestHeaders });
    }

    updateVehicle(taxid: string, data: string) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('content-type', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + taxid + '/vehicle', data, { headers: RequestHeaders });
    }

    expertBid(id: number, data: expertBid) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/expert-bid', data, { headers: RequestHeaders });
    }

    updateValuationComment(taxid: number, data: any, commentid: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('content-type', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + taxid + '/comment/' + commentid, data, { headers: RequestHeaders });
    }

    linkExpert(id: number, data: linkExpert) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/link-expert', data, { headers: RequestHeaders });
    }

    finishValuation(id: number, data: finishValuation) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/finish', data, { headers: RequestHeaders });
    }

    changeDateDelivery(id: number, data: dateDelivery) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/date-delivery', data, { headers: RequestHeaders });
    }

    valuationAvailable(id: number, data: available) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/available', data, { headers: RequestHeaders });
    }

    createContactTask(id: number, data: contactTask) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/contact-task', data, { headers: RequestHeaders });
    }

    transportAddress(id: number, data: transportAddress) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/transport-address', data, { headers: RequestHeaders });
    }

    registerTransport(id: number, data: registerTransport) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/transport', data, { headers: RequestHeaders });
    }

    transported(id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/transported', { headers: RequestHeaders });
    }

    sellValuation(id: number, data: sellAction) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/sell', data, { headers: RequestHeaders });
    }

    prepareSale(id: number, data: prepareSale) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/prepare-sale', data, { headers: RequestHeaders });
    }

    closeValuationTask(id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/task/' + id + '/close', { headers: RequestHeaders });
    }

    closeValuationTasks(data: closeValuationTasks) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/task/close', data, { headers: RequestHeaders });
    }

    updateDamageSpecification(id: number, data: any) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/damage-specification', data, { headers: RequestHeaders });
    }

    assignExpertToValuation(id: number, data: assignExpertToValuation) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/assign-expert', data, { headers: RequestHeaders });
    }

    updateValuationDocument(id: number, documentId: number, data: updateValuationDocument) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/document/' + documentId, data, { headers: RequestHeaders });
    }

    updateCustomer(id: number, data: updateCustomer) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/customer/', data, { headers: RequestHeaders });
    }

    mailPaymentRequest(id: number, data: paymentRequest) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/payment-request/', data, { headers: RequestHeaders });
    }

    saleStatus(id: number, data: saleStatus) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/sale-status/', data, { headers: RequestHeaders });
    }

    updateVehicleList(id: number, data: any) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/vehicle-list/' + id, data, { headers: RequestHeaders });
    }

    reactivateValuation(id: number, data: Reactivate) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/reactivate', data, { headers: RequestHeaders });
    }

    // DELETE
    deleteValuation(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id, { headers: RequestHeaders });
    }

    deleteSpecification(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/specification/' + id, { headers: RequestHeaders });
    }

    deleteComment(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/comment/' + id, { headers: RequestHeaders });
    }

    deleteValuationDocument(id: number, documentId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/document/' + documentId, { headers: RequestHeaders });
    }

    deleteVehicleAccessory(id: number, accessoryId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/vehicle-accessory/' + accessoryId, { headers: RequestHeaders });
    }

    deleteSaleStatus(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/sale-status/', { headers: RequestHeaders });
    }

    deleteAvailableStatus(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/available/', { headers: RequestHeaders });
    }

    // POST
    addValuationDocument(id: number, params: valuationDocument) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/document', params, { headers: headers });
    }

    topDownCalculation(id: number, params: topDownCalculation) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/top-down-calculation', params, { headers: headers });
    }

    topDownCalculationVehicleList(id: number, params: topDownCalculation) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + id + '/top-down-calculation', params, { headers: headers });
    }

    createValuationComment(id: number, params: valuationComment) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/comment', params, { headers: headers });
    }

    createValuationDocument(id: number, params: valuationDocument) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/document', params, { headers: headers });
    }

    createValuationClaim(id: number, params: valuationClaim) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/claim', params, { headers: headers });
    }

    createMappingForModelSpecific(params: createModelMapping) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/model-specific', params, { headers: headers });
    }

    createMappingForModel(id: number, params: valuationClaim) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/model/' + id, params, { headers: headers });
    }

    createVehicleList(companyId: number, file: File) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        const formData = new FormData();
        formData.append('companyId', companyId.toString());
        formData.append('vehicleListFile', file);

        return this.http.post<apiResponse>(this.api_base + '/request/v1/vehicle-list/', formData, { headers: headers });
    }

    //OPTIONS
    getTaskOptions() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.options<apiResponse>(this.api_base + '/request/v1/valuation/task', { headers: RequestHeaders });
    }

    getReferenceBidOptions() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.options<apiResponse>(this.api_base + '/request/v1/valuation/reference-bid', { headers: RequestHeaders });
    }

    /*-------------------------
    == CRM CALLS ==
    -------------------------*/
    // GET
    getCompanies(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/company',
            { headers: RequestHeaders, params: Params });
    }

    getPersons(limit: any, page: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/person',
            { headers: RequestHeaders, params: Params });
    }

    getCompanyId(companyID: number, fields?: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/company/' + companyID, { headers: RequestHeaders, params: Params });
    }

    getPersonID(personID: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/person/' + personID, { headers: RequestHeaders });
    }

    getEmployeeID(employeeID: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/employee/' + employeeID, { headers: RequestHeaders });
    }

    getAddressTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/address-type/', { headers: RequestHeaders, params: Params });
    }

    getPhoneTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/phone-type/', { headers: RequestHeaders, params: Params });
    }

    getEmailTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/email-type', { headers: RequestHeaders, params: Params });
    }

    getCompanyTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/company-type/', { headers: RequestHeaders, params: Params });
    }

    getWebsiteTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/website-type', { headers: RequestHeaders, params: Params });
    }

    getSubscription(fields: string, id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('fields', String(fields));
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/subscription/' + id, { headers: RequestHeaders, params: Params });
    }

    getEmployees(limit: number, page: number, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/employee/', { headers: RequestHeaders, params: Params });
    }

    getSubscriptionTypes(limit: number, page: number, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/subscription-type/',
            { headers: RequestHeaders, params: Params });
    }

    getCompanyEmail(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/company-email/',
            { headers: RequestHeaders, params: Params });
    }

    getPersonEmail(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/person-email/',
            { headers: RequestHeaders, params: Params });
    }

    getBidFilterConfig(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/bid-filter-config/' + id,
            { headers: RequestHeaders, params: Params });
    }

    getBidFilter(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/bid-filter/' + id,
            { headers: RequestHeaders, params: Params });
    }

    testBidFilter(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/bid-filter-config/' + id + '/test',
            { headers: RequestHeaders, params: fields });
    }

    testSpecificBidFilter(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/bid-filter/' + id + '/test',
            { headers: RequestHeaders, params: fields });
    }

    getBidFilterOperators() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/bid-filter/operator/',
            { headers: RequestHeaders });
    }

    getBidFilterFields() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/bid-filter/field/',
            { headers: RequestHeaders });
    }

    getDealership(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/dealership/' + id,
            { headers: RequestHeaders, params: Params });
    }

    getSpecialism(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/specialism/' + id,
            { headers: RequestHeaders, params: Params });
    }

    sendCompanyLogin(employeeId: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/employee/' + employeeId + '/company-login',
            { headers: RequestHeaders });
    }

    getInspectionFilterConfig(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/inspection-filter-config/' + id,
            { headers: RequestHeaders });
    }

    getInspectionFilter(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/inspection-filter/' + id,
            { headers: RequestHeaders });
    }

    verifyInspectionFilterForValuation(valuationId: any) {
        const headers = new HttpHeaders().append('Accept', 'application/json');
        const params = new HttpParams().append('valuationId', valuationId);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/inspection-filter-config/verify', { params, headers });
    }

    getInspectionFilterFields() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/inspection-filter/field',
            { headers: RequestHeaders });
    }

    getFunctionTypes() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/function-type',
            { headers: RequestHeaders });
    }

    getInspectionFilterOperators() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/inspection-filter/operator',
            { headers: RequestHeaders });
    }

    getCompanyAlias(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/company-alias/' + id,
            { headers: RequestHeaders, params: Params });
    }

    getEmployeePropertyId(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/employee-property/' + id,
            { headers: RequestHeaders, params: Params });
    }

    getPersonPropertyId(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/person-property/' + id,
            { headers: RequestHeaders, params: Params });
    }

    getPropertyTypeId(id: number, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/property-type/' + id,
            { headers: RequestHeaders, params: Params });
    }

    getPropertyTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/crm/v1/property-type/',
            { headers: RequestHeaders, params: Params });
    }

    // POST
    addEmail(data: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/company-email/', data, { headers: headers });
    }

    addPersonEmail(data: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/person-email/', data, { headers: headers });
    }

    addPhone(data: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/company-phone/', data, { headers: headers });
    }

    addPersonPhone(data: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/person-phone/', data, { headers: headers });
    }

    addWebsite(data: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/company-website/', data, { headers: headers });
    }

    addAddress(data: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/company-address/', data, { headers: headers });
    }

    addEmployee(data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json')
        RequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/employee', data, { headers: RequestHeaders });
    }

    addPerson(data: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/person/', data, { headers: headers });
    }

    addCompany(data: company) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/company', data, { headers: RequestHeaders });
    }

    addRequestPreference(data: requestPreference) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/request-preference', data, { headers: RequestHeaders });
    }

    addSubscription(data: addSubscription) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/subscription', data, { headers: RequestHeaders });
    }

    addBidFilterConfiguration(data: bidFilterConfig) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/bid-filter-config/', data, { headers: RequestHeaders });
    }

    addBidFilter(data: BidFilterUpdate) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/bid-filter/', data, { headers: RequestHeaders });
    }

    addDealership(data: dealership) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/dealership/', data, { headers: RequestHeaders });
    }

    addSpecialism(data: specialism) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/specialism/', data, { headers: RequestHeaders });
    }

    addInspectionFilterConfiguration(data: inspectionFilterConfig) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/inspection-filter-config/', data, { headers: RequestHeaders });
    }

    addInspectionFilter(data: InspectionFilterUpdate) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/inspection-filter/', data, { headers: RequestHeaders });
    }

    addCompanyAlias(data: companyAlias) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/company-alias/', data, { headers: RequestHeaders });
    }

    addCompanyProperty(data: companyProperty) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/crm/v1/company-property/', data, { headers: RequestHeaders });
    }
    // DELETE
    deleteCompanyEmail(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/company-email/' + id, { headers: RequestHeaders });
    }

    deletePersonEmail(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/person-email/' + id, { headers: RequestHeaders });
    }

    deleteCompanyPhone(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/company-phone/' + id, { headers: RequestHeaders });
    }

    deletePersonPhone(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/person-phone/' + id, { headers: RequestHeaders });
    }

    deleteRequestPreference(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/request-preference/' + id, { headers: RequestHeaders });
    }

    deleteCompanyAddress(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/company-address/' + id, { headers: RequestHeaders });
    }

    deleteSubscription(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/subscription/' + id, { headers: RequestHeaders });
    }

    deleteEmployee(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/employee/' + id, { headers: RequestHeaders });
    }

    deleteBidFilter(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/bid-filter/' + id, { headers: RequestHeaders });
    }

    deleteSpecialism(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/specialism/' + id, { headers: RequestHeaders });
    }

    deleteDealership(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/dealership/' + id, { headers: RequestHeaders });
    }

    deleteInspectionFilter(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/inspection-filter/' + id, { headers: RequestHeaders });
    }

    deleteEmployeeProperty(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/employee-property/' + id, { headers: RequestHeaders });
    }

    deleteCompanyAlias(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/company-alias/' + id, { headers: RequestHeaders });
    }

    deleteCompanyProperty(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/crm/v1/company-property/' + id, { headers: RequestHeaders });
    }

    // Update
    updateCompany(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/company/' + id, data, { headers: RequestHeaders });
    }

    updateCompanyEmail(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/company-email/' + id, data, { headers: RequestHeaders });
    }

    updatePersonEmail(id: number, data: any) {

        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/person-email/' + id, data, { headers: RequestHeaders });
    }

    updateCompanyPhone(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/company-phone/' + id, data, { headers: RequestHeaders });
    }

    updatePersonPhone(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/person-phone/' + id, data, { headers: RequestHeaders });
    }

    updateCompanyAddress(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/company-address/' + id, data, { headers: RequestHeaders });
    }

    updateCompanyWebsite(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/company-website/' + id, data, { headers: RequestHeaders });
    }

    updateRequestPreference(id: number, data: requestPreference) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/request-preference/' + id, data, { headers: RequestHeaders });
    }

    updatePerson(id: number, data: updatePerson) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/person/' + id, data, { headers: RequestHeaders });
    }

    updateSubscription(id: number, data: editSubscription) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/subscription/' + id, data, { headers: RequestHeaders });
    }

    updateBidFilterConfiguration(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/bid-filter-config/' + id, data, { headers: RequestHeaders });
    }

    updateBidFilter(id: number, data: BidFilterUpdate) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/bid-filter/' + id, data, { headers: RequestHeaders });
    }

    updateSpecialism(id: number, data: specialism) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/specialism/' + id, data, { headers: RequestHeaders });
    }

    updateDealership(id: number, data: dealership) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/dealership/' + id, data, { headers: RequestHeaders });
    }

    updateInspectionFilterConfig(id: number, data: inspectionFilterConfig) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/inspection-filter-config/' + id, data, { headers: RequestHeaders });
    }

    updateInspectionFilter(id: number, data: InspectionFilterUpdate) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/inspection-filter/' + id, data, { headers: RequestHeaders });
    }

    updateEmployeeProperty(id: number, data: employeeProperty) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/employee-property/' + id, data, { headers: RequestHeaders });
    }

    updateEmployee(id: number, data: employee) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/employee/' + id, data, { headers: RequestHeaders });
    }

    updateCompanyAlias(id: number, data: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/company-alias/' + id, data, { headers: RequestHeaders });
    }

    updateCompanyProperty(id: number, data: companyProperty) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/crm/v1/company-property/' + id, data, { headers: RequestHeaders });
    }

    //OPTIONS
    gatherPersonPropertyOptions() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.options<apiResponse>(this.api_base + '/crm/v1/person-property/', { headers: RequestHeaders });
    }

    getRequestPreferenceTypes(filter?: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (filter) Params = Params.append('filters', String(filter));
        return this.http.options<apiResponse>(this.api_base + '/crm/v1/request-preference/', { headers: RequestHeaders, params: Params });
    }

    // PATCH
    patchRequestPreference(id: number, data: patchRequestPreference) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.patch<apiResponse>(this.api_base + '/crm/v1/request-preference/' + id, data, { headers: headers });
    }

    /*-------------------------
    == VEHICLE CALLS ==
    -------------------------*/

    // GET
    getAircoStatusTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/airco-status-type/', { headers: RequestHeaders });
    }

    getAircoStatusTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/airco-status-type/' + id, { headers: RequestHeaders });
    }

    getBodyTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/body-type/', { headers: RequestHeaders });
    }

    getBodyTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/body-type/' + id, { headers: RequestHeaders });
    }

    getColorTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/color-type/', { headers: RequestHeaders });
    }

    getColorTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/color-type/' + id, { headers: RequestHeaders });
    }

    getConditionTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/condition-type/', { headers: RequestHeaders });
    }

    getConditionTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/condition-type/' + id, { headers: RequestHeaders });
    }

    getCountryOfOriginTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/country-of-origin-type/', { headers: RequestHeaders });
    }

    getCountryOfOriginTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/country-of-origin-type/' + id, { headers: RequestHeaders });
    }

    getDriveTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/drive-type/', { headers: RequestHeaders });
    }

    getDriveTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/country-of-origin-type/' + id, { headers: RequestHeaders });
    }

    getFuelTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/fuel-type/', { headers: RequestHeaders });
    }

    getFuelTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/country-of-origin-type/' + id, { headers: RequestHeaders });
    }

    getInteriorColorTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/interior-color-type/', { headers: RequestHeaders });
    }

    getInteriorColorTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/interior-color-type/' + id, { headers: RequestHeaders });
    }

    getInteriorTouchTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/interior-touch-type/', { headers: RequestHeaders });
    }

    getInteriorTouchTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/interior-touch-type/' + id, { headers: RequestHeaders });
    }

    getLacTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/lac-type/', { headers: RequestHeaders });
    }

    getLacTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/lac-type/' + id, { headers: RequestHeaders });
    }

    getLiningTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/lining-type/', { headers: RequestHeaders });
    }

    getLiningTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/lining-type/' + id, { headers: RequestHeaders });
    }

    getMaintenanceGuideTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/maintenance-guide-type/', { headers: RequestHeaders });
    }

    getMileageHistoryStatusTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/mileage-history-status-type/', { headers: RequestHeaders });
    }

    getMaintenanceGuideID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/maintenance-guide-type/', { headers: RequestHeaders });
    }

    getMaintenanceHistoryTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/maintenance-history-type/', { headers: RequestHeaders });
    }

    getMaintenanceHistoryTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/maintenance-history-type/' + id, { headers: RequestHeaders });
    }

    getMakes() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/make/?limit=1000', { headers: RequestHeaders });
    }

    getModels(limit: any, page: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/model', { headers: RequestHeaders, params: Params });
    }

    getModelSpecifics(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/model-specific/', { headers: RequestHeaders, params: Params });
    }

    getModelSpecificsID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/model-specific/' + id, { headers: RequestHeaders });
    }

    getNapManualStatusTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/nap-manual-status-type/', { headers: RequestHeaders });
    }


    getNapManualStatusTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/nap-manual-status-type/' + id, { headers: RequestHeaders });
    }

    getTimingBeltReplacedTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/timing-belt-replaced-type/', { headers: RequestHeaders });
    }

    getTimingBeltReplacedTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/timing-belt-replaced-type/', { headers: RequestHeaders });
    }

    getTransmissionTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/transmission-type/', { headers: RequestHeaders, params: Params });
    }

    getTransmissionTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/transmission-type/' + id, { headers: RequestHeaders });
    }

    getUsedAsTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/used-as-type/', { headers: RequestHeaders, params: Params });
    }

    getUsedAsTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/used-as-type/' + id, { headers: RequestHeaders });
    }

    getVatMarginTypes(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vat-margin-type/', { headers: RequestHeaders });
    }

    getVatMarginTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vat-margin-type/' + id, { headers: RequestHeaders });
    }

    getVehicleTypes() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-type/', { headers: RequestHeaders });
    }

    getVehicleTypeID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-type/' + id, { headers: RequestHeaders });
    }

    getOriginChannelTypes() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/origin-channel-type/', { headers: RequestHeaders });
    }

    getOriginCommercialTypes() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/origin-commercial-type/', { headers: RequestHeaders });
    }

    //  PUT
    updateModelMapping(mappingId: number, data: ModelMapping) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/request/v1/mapping/model/' + mappingId, data, { headers: RequestHeaders });
    }

    /*-------------------------
    == 3RD-PARTY CALLS ==
    -------------------------*/

    // GET
    getAutotrackModels(limit: any, page: any, orderBy: string | null, filter: any, fields: any, defaultFilter: any) {
        return this.http.get<apiResponse>(this.api_base + '/3rd-party/v1/autotrack/make?' + 'limit=' + limit + ' & page=' +
            page + '&orderBy=' + orderBy + '&filter=' + filter + '&defaultFilter=' +
            defaultFilter + '&fields=' + fields);
    }

    getAutotrackTranslations(limit: any, page: any, orderBy: string, filter: any, fields: any, defaultFilter: any) {
        return this.http.get<any[]>(this.api_base + '/3rd-party/v1/autotrack/translation?' + 'limit=' + limit + ' & page=' +
            page + '&orderBy=' + orderBy + '&filter=' + filter + '&defaultFilter=' +
            defaultFilter + '&fields=' + fields);
    }

    // POST
    valuateByJpCars(type: string, referenceId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/3rd-party/v1/jp-cars/valuate', { type: type, referenceId: referenceId }, { headers: RequestHeaders });
    }

    /*---------------------------------------
    == REQUEST - VEHICLE DESCRIPTION CALLS ==
    ---------------------------------------*/

    // DELETE
    deleteImage(descriptionId: number, imageId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + descriptionId + '/image/' + imageId, { headers: RequestHeaders });
    }

    deleteImageVehicleList(vehicleId: number, imageId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + vehicleId + '/image/' + imageId, { headers: RequestHeaders });
    }

    // POST
    addImage(data: addImage, descriptionId: number) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        // headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/valuation/' + descriptionId + '/image/', data, { headers: headers });
    }

    addImageVehicleList(data: addImage, vehicleId: number) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        // headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/request/v1/vehicle-list/vehicle/' + vehicleId + '/image/', data, { headers: headers });
    }

    // PUT
    updateImage(descriptionId: string, imageId: number, params: updateImage) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + descriptionId + '/image/' + imageId, params, { headers: RequestHeaders });
    }

    updateDamge(damgeId: number, valuationId: number, params: updateDamage) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + valuationId + '/damage/' + damgeId, params, { headers: RequestHeaders });
    }

    /*-------------------------
    == COMMUNICATION CALLS ==
    ---------------------------*/

    // GET
    getAnnouncements(limit?: any, page?: any, orderBy?: string | null, filter?: any | null, fields?: any | null, defaultFilter?: any | null, groupBy?: any | null) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/communication/v1/announcement/', { headers: RequestHeaders, params: Params });
    }

    getAnnouncementID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/communication/v1/announcement/' + id, { headers: RequestHeaders });
    }

    // POST
    addAnnouncement(data: announcement) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/communication/v1/announcement', data, { headers: headers });
    }

    addClientToAnnouncement(data: any, announcementID: number) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/communication/v1/announcement/' + announcementID + '/client/', data, { headers: headers });
    }

    // PUT
    updateAnnouncement(data: announcement, id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/communication/v1/announcement/' + id, data, { headers: RequestHeaders });
    }

    // DELETE
    deleteAnnouncement(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/communication/v1/announcement/' + id, { headers: RequestHeaders });
    }

    deleteClientFromAnnouncement(announcementID: number, announcementclientID: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.delete<apiResponse>(this.api_base + '/communication/v1/announcement/' + announcementID + '/client/' + announcementclientID, { headers: RequestHeaders });
    }

    /*-------------------------
    == TRADE- CALLS ==
    -------------------------*/

    // GET
    getBidTerm(limit: number, page: number, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/trade/v1/bid-term',
            { headers: RequestHeaders, params: Params });
    }

    // POST
    mailValuationOffer(data: valuationOffer) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/trade/v1/valuation-offer', data, { headers: headers });
    }

    /*-------------------------
    == STATISTICS- CALLS ==
    -------------------------*/

    // GET
    statisticsSearch(index: string, requestBody: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('index', index);
        Params = Params.append('requestBody', requestBody);
        return this.http.get<Statistics>(this.api_base + '/statistics/v1/query',
            { headers: RequestHeaders, params: Params });
    }

    statisticsCount(index: string, requestBody: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('index', index);
        Params = Params.append('requestBody', requestBody);
        return this.http.get<Statistics>(this.api_base + '/statistics/v1/count',
            { headers: RequestHeaders, params: Params });
    }

    /*-------------------------
    == ACCOUNTING- CALLS ==
    -------------------------*/

    // GET
    getAccountingJobs(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/accounting/v1/job',
            { headers: RequestHeaders, params: Params });
    }

    // PUT
    runAccountingJobs() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/accounting/v1/job/run', { headers: RequestHeaders });
    }

    // DELETE
    deleteAccountingJob(id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/accounting/v1/job/' + id, { headers: RequestHeaders });
    }

    // POST
    addAccountingJob(data: accountingJob) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.post<apiResponse>(this.api_base + '/accounting/v1/job', data, { headers: RequestHeaders });
    }
}
