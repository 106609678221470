import { Injectable } from '@angular/core';
import OpenAI from 'openai';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpenAIService {
  private client: OpenAI;

  constructor() {
    // Initialize the OpenAI client
    this.client = new OpenAI({
      apiKey: environment.openAiSecretKey,
      organization: environment.openAiOrganizationId,
      dangerouslyAllowBrowser: true,
    });
  }

  /**
   * Create a regular chat completion (non-streaming).
   * @param messages Array of chat messages for the conversation.
   * @param model The model to use (e.g., 'gpt-4').
   * @returns Promise with the full OpenAI chat completion response.
   */
  async createChatCompletion(
    messages: OpenAI.Chat.ChatCompletionCreateParams['messages'],
    model: string = 'gpt-4o'
  ): Promise<string> {
    try {
      const params: OpenAI.Chat.ChatCompletionCreateParams = {
        messages,
        model,
      };

      const response = await this.client.chat.completions.create(params);

      // Extract the assistant's reply
      const content = response.choices[0].message.content;
      if (content === null) {
        throw new Error('Received null content from OpenAI');
      }
      return content;
    } catch (error) {
      console.error('Error creating chat completion:', error);
      throw error;
    }
  }

  /**
   * Create a streaming chat completion.
   * @param messages Array of chat messages for the conversation.
   * @param model The model to use (e.g., 'gpt-4').
   * @returns Async generator that yields individual chunks of the streamed response.
   */
  async *streamChatCompletion(
    messages: OpenAI.Chat.ChatCompletionCreateParams['messages'],
    model: string = 'gpt-4o'
  ): AsyncGenerator<string, void, undefined> {
    try {
      const params: OpenAI.Chat.ChatCompletionCreateParamsStreaming = {
        messages,
        model,
        stream: true, // Enable streaming
      };

      const stream = await this.client.chat.completions.create(params);

      // Yield chunks as they arrive
      for await (const chunk of stream) {
        const deltaContent = chunk.choices[0]?.delta?.content || '';
        if (deltaContent) {
          yield deltaContent;
        }
      }
    } catch (error) {
      console.error('Error with streaming chat completion:', error);
      throw error;
    }
  }

  /**
   * Append a user or assistant message to the chat history.
   * This is a helper method to maintain the chat context.
   * @param history The chat message history array.
   * @param role The role of the new message ('user' or 'assistant').
   * @param content The content of the new message.
   */
  addMessage(
    history: OpenAI.Chat.ChatCompletionCreateParams['messages'],
    role: 'user' | 'assistant' | 'system',
    content: string
  ): void {
    history.push({ role, content });
  }
}
