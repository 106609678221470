import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpenAIService } from 'src/app/modules/general/services/openai.service';
import { OpenAI } from 'openai';
import { ApiService } from 'src/app/modules/api.service';
import { marked } from 'marked';

@Component({
  selector: 'app-ai-hulp-component',
  templateUrl: 'ai-hulp.modal.html',
  styleUrls: ['ai-hulp.modal.scss']
})
export class AiHulpModalComponent {
  public messages: OpenAI.Chat.ChatCompletionCreateParams['messages'] = [
    {
      role: 'system',
      content: '',
    },
  ];

  public filteredMessages: OpenAI.Chat.ChatCompletionCreateParams['messages'] = [];
  public userInput: string = '';
  public streamingMessage: string = '';

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AiHulpModalComponent>,
    private openAiService: OpenAIService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: { valuationId?: number }
  ) {
    marked.setOptions({
      async: false,
      breaks: true,
      gfm: true,
    });

    // Retrieve the current user's name
    const authUserInformation = JSON.parse(localStorage.getItem('authUserInformation') || '{}');
    const person = authUserInformation.Person || {};
    const firstname = person.firstname || 'Onbekend';
    const infix = person.infix ? ` ${person.infix}` : '';
    const lastName = person.lastName || 'Gebruiker';
    const today = new Date().toLocaleDateString();

    // Initialize system message
    this.messages[0].content = `
      Je bent een assistant voor het bedrijf Autotaxatie Partners. 
      Werknemers van Autotaxatie Partners ga je ondersteunen met diverse dagelijkse taken. 
      De naam van de Autotaxatie Partners werknemer die je nu helpt is: ${firstname}${infix} ${lastName}. 
      Deze naam kun je gebruiken bij bijvoorbeeld het opstellen van mailtjes.
      Mocht je het nodig hebben, de datum van vandaag is ${today}.
      Antwoord direct op de vraag van de werknemer.
      Doe exact wat de werknemer vraagt, in de volgende systeem prompt heb je meer informatie over wat de werknemer nu in beeld heeft.
    `;

    // Fetch valuation data if available
    if (this.data.valuationId) {
      const fields = ["AssignedCompany", "Company", "Employee", "StatusBought",
        "StatusType", "ValuationType", "VehicleSpecification", "assignedDate", "assignedPrice", "created",
        "dateDelivery", "dateDeliveryBought", "dateDeliveryOrig", "dgwAmount", "externalBid", "firstBidAmount",
        "gvExpirationDate", "id", "isApproved", "receivedInvoice", "requestedInspection",
        "salePriceBusiness", "salePriceConsumer", "saleRemarks", "isAvailable", "dateDelivered"];

      // Fetch valuation data if available
      if (this.data.valuationId) {
        this.fetchValuationData(this.data.valuationId);
      }
    }
  }

  public translateRole(role: string): string {
    const roleTranslations: { [key: string]: string } = {
      user: 'Gebruiker',
      assistant: 'Assistent',
    };
    return roleTranslations[role] || role;
  }

  public async sendStreamingMessage() {
    if (!this.userInput.trim()) return;

    // Add user's message to the conversation
    this.openAiService.addMessage(this.messages, 'user', this.userInput);
    this.userInput = '';
    this.streamingMessage = '';

    try {
      // Stream the assistant's reply
      const stream = this.openAiService.streamChatCompletion(this.messages);

      for await (const chunk of stream) {
        this.streamingMessage += chunk.replace(/\n/g, '<br>'); // Convert line breaks to HTML
      }

      // Add the assistant's full reply to the conversation
      this.openAiService.addMessage(this.messages, 'assistant', this.streamingMessage);
      this.streamingMessage = '';

      // Update filtered messages
      this.filteredMessages = this.messages.filter(msg => msg.role !== 'system');
    } catch (error) {
      console.error('Error during streaming chat:', error);
    }
  }

  private fetchValuationData(valuationId: number): void {
    const fields = [
      "AssignedCompany",
      "Company",
      "Employee",
      "StatusBought",
      "StatusType",
      "ValuationType",
      "VehicleSpecification-Accessories",
      "VehicleSpecification-BodyType",
      "VehicleSpecification-ColorType",
      "VehicleSpecification-ConditionBodyType",
      "VehicleSpecification-ConditionTechnicalType",
      "VehicleSpecification-DriveType",
      "VehicleSpecification-FuelType",
      "VehicleSpecification-Mileage",
      "VehicleSpecification-TransmissionType",
      "VehicleSpecification-VehicleType",
      "VehicleSpecification-id",
      "VehicleSpecification-type",
      "VehicleSpecification-numberOfDoors",
      "VehicleSpecification-numberOfSeats",
      "VehicleSpecification-newPrice",
      "VehicleSpecification-power",
      "VehicleSpecification-powerPK",
      "VehicleSpecification-restBpm",
      "VehicleSpecification-dateApkExpires",
      'VehicleSpecification-Vehicle-registration',
      'VehicleSpecification-Vehicle-make',
      'VehicleSpecification-Vehicle-model',
      'VehicleSpecification-Vehicle-modelSpecific',
      'VehicleSpecification-Vehicle-dateFirstAdmission',
      'VehicleSpecification-Vehicle-dateFirstRegistration',
      'VehicleSpecification-dateLastRegistration',
      'VehicleSpecification-typeCommercial',
      'VehicleSpecification-Vehicle-year',
      'VehicleSpecification-mileage',
      'VehicleSpecification-mileageType',
      'VehicleSpecification-FuelType',
      "assignedDate",
      "assignedPrice",
      "created",
      "dateDelivery",
      "dateDeliveryBought",
      "dateDeliveryOrig",
      "dgwAmount",
      "externalBid",
      "firstBidAmount",
      "gvExpirationDate",
      "id",
      "isApproved",
      "receivedInvoice",
      "requestedInspection",
      "salePriceBusiness",
      "salePriceConsumer",
      "saleRemarks",
      "isAvailable",
      "dateDelivered",
    ];

    this.apiService.getValuationID(valuationId, JSON.stringify(fields)).subscribe({
      next: (response: any) => {
        // Transform data to make it more descriptive
        const transformedData = this.transformValuationData(response.data);

        // Add the transformed valuation data to the conversation
        this.openAiService.addMessage(this.messages, 'system', JSON.stringify(transformedData));
      },
      error: (err) => {
        console.error('Error fetching valuation data:', err);
      },
    });
  }

  private transformValuationData(data: any): any {
    return {
      AssignedCompany: {
        value: data.AssignedCompany,
        description: "Het waaraan de taxatie is toegewezen.",
      },
      Company: {
        value: data.Company,
        description: "Het bedrijf dat de taxatie aanvraagt.",
      },
      Employee: {
        value: data.Employee,
        description: "De werknemer die de taxatie heeft gemaakt.",
      },
      StatusBought: {
        value: data.StatusBought,
        description: "Geeft aan of het voertuig is gekocht.",
      },
      StatusType: {
        value: data.StatusType,
        description: "Type status voor de taxatie.",
      },
      ValuationType: {
        value: data.ValuationType,
        description: "Het type taxatie dat wordt uitgevoerd.",
      },
      VehicleSpecification: {
        value: data.VehicleSpecification,
        description: "Details over het voertuig dat wordt getaxeerd.",
      },
      assignedDate: {
        value: data.assignedDate,
        description: "De datum waarop de taxatie is toegewezen.",
      },
      assignedPrice: {
        value: data.assignedPrice,
        description: "De toegewezen prijs voor de taxatie.",
      },
      created: {
        value: data.created,
        description: "De datum waarop de taxatie is aangemaakt.",
      },
      dateDelivery: {
        value: data.dateDelivery,
        description: "De leverdatum voor de taxatie.",
      },
      dateDeliveryBought: {
        value: data.dateDeliveryBought,
        description: "De leverdatum waarop het voertuig is gekocht.",
      },
      dateDeliveryOrig: {
        value: data.dateDeliveryOrig,
        description: "De oorspronkelijke leverdatum.",
      },
      dgwAmount: {
        value: data.dgwAmount,
        description: "Het DGW-bedrag (koop) gerelateerd aan de taxatie.",
      },
      externalBid: {
        value: data.externalBid,
        description: "Externe bieding gerelateerd aan de taxatie.",
      },
      firstBidAmount: {
        value: data.firstBidAmount,
        description: "Het eerste biedingsbedrag.",
      },
      gvExpirationDate: {
        value: data.gvExpirationDate,
        description: "Vervaldatum van de taxatie.",
      },
      id: {
        value: data.id,
        description: "Unieke identificatiecode van de taxatie.",
      },
      isApproved: {
        value: data.isApproved,
        description: "Geeft aan of de taxatie inspectie is goedgekeurd.",
      },
      receivedInvoice: {
        value: data.receivedInvoice,
        description: "Geeft aan of er een factuur is ontvangen.",
      },
      requestedInspection: {
        value: data.requestedInspection,
        description: "Geeft aan of er een inspectie is aangevraagd.",
      },
      salePriceBusiness: {
        value: data.salePriceBusiness,
        description: "De verkoopprijs voor bedrijven.",
      },
      salePriceConsumer: {
        value: data.salePriceConsumer,
        description: "De verkoopprijs voor consumenten.",
      },
      saleRemarks: {
        value: data.saleRemarks,
        description: "Opmerkingen met betrekking tot de verkoop.",
      },
      isAvailable: {
        value: data.isAvailable,
        description: "Geeft aan of de auto beschikbaar is.",
      },
      dateDelivered: {
        value: data.dateDelivered,
        description: "De datum waarop de taxatie is geleverd.",
      },
    };
  }

  public parseMarkdown(content: string): string {
    try {
      // Ensure synchronous parsing
      const result = marked.parse(content);
      return typeof result === 'string' ? result : content; // Fallback to raw content if parsing fails
    } catch (error) {
      console.error('Error parsing markdown:', error);
      return content; // Fallback to plain content
    }
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }
}
